import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './auth/auth.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public isReporting: boolean;
  constructor(private router: Router) {
    
    router.events.subscribe(routingEvent => {
      if (routingEvent instanceof NavigationEnd) {
        // if this is a repoting page, lets set the page width to max - because reports are long
        this.isReporting = routingEvent.urlAfterRedirects.includes('/admin/reporting');
      }
    });
  }
}
