import { Customer } from "../customer-management/customers.models";

export class UserInfo {
  public id: string;
  public userName: string;
  public email: string;
  public role: string;
  public customerId: string;
  public customer: Customer;
  public get customerName() {
    return this.customer && this.customer.name;
  }
}


export class PasswordReset {
  public password1: string;
  public password2: string;

  public get passwordsNotMatching(): boolean {
    return this.password1 && this.password1.length > 1 && this.password2 && this.password2.length > 1 && this.password1 !== this.password2;
  }
}
