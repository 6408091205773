import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { User } from '../../auth/auth.models';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.css']
})
export class MessengerComponent implements OnInit {


  public user: User;


  constructor(private auth: AuthService) {
    auth.isLoggedIn$.subscribe(x => {
      if (x) {
        this.onLogin()
      }
      else {
        this.onLogout();
      }
    });
  }

  ngOnInit(): void {
    if (this.auth.isLoggedIn) {
      this.createIntercom();
    }
  }

  public createIntercom() {
    (window as any).intercomSettings = {
      app_id: "x1v62pdv",
      name: this.auth.currentUser.username
    };

    var w = window as any;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      } as any;
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };

      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/x1v62pdv';
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  }

  public onLogin() {
    this.createIntercom();
  }

  public onLogout() {
    let intercom = (window as any).Intercom;
    if (intercom) {
      intercom('shutdown');
    }
  }
}
