import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrucksManagementModule } from '../admin/trucks-management/trucks-management.module';
import { FormsModule } from '@angular/forms';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { TripsListComponent } from './trips-list/trips-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ TripsListComponent],
  imports: [
    CommonModule,
    TrucksManagementModule,
    FormsModule,
    RouterModule,
    NgbModule
  ]
})
export class TripsModule { }
