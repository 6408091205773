import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersManagerComponent } from './customers-manager/customers-manager.component';
import { FormsModule } from '@angular/forms';
import { CustomersService } from './customers-service.service';

@NgModule({
  declarations: [CustomersManagerComponent],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class CustomerManagementModule { }
