import { Component, OnInit, Input, } from '@angular/core';
import { Bag, Parcel } from './package-input.models';
import { PackageInputService } from './package-input.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintModalComponent } from '../print-modal/print-modal.component';
import { DuplicatePackagesModalComponent } from '../workflow/common-ui/duplicate-packages-modal/duplicate-packages-modal.component';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-package-input',
  templateUrl: './package-input.component.html',
  styleUrls: ['./package-input.component.css'],
  entryComponents: [DuplicatePackagesModalComponent]
})
export class PackageInputComponent implements OnInit {

  public bag: Bag;
  public duplicatePackageFound = false;
  public multiPackageCodesEnabled = false; // enabled to true when user pastes into package input
  public userRole: string = this.auth.currentUser.roles[0];
  
  public submittedBags: Bag[];

  @Input()
  scannedParcelCode: string;


  constructor(private packageService: PackageInputService, private modalService: NgbModal, private auth: AuthService) { }

  ngOnInit() {
    this.packageService.getBags().subscribe(x => {
      this.submittedBags = x;
    });
  }

  public generateBag(destination: string) {

    if (this.multiPackageCodesEnabled) {
      // we have a multiple packages codes in a single string here.
      let codes = this.scannedParcelCode.split('\n');
      codes.forEach(x => this.bag.addParcel(x));
      this.multiPackageCodesEnabled = false;
      this.scannedParcelCode = null;
    }


    if (!!this.bag) { // bag already exists, send to the server
      if (!this.bag.bagId && this.bag.parcels.length == 0) {
        // new empty bag closing. Nothing to send.
        this.bag = null;
        return;
      }

      this.packageService.saveBag(this.bag).subscribe(
        (result) => {
          // new or updated bag here
          let idx = this.submittedBags.findIndex(x => x.bagId === result.bagId);
          if (idx > -1) {
            this.submittedBags[idx] = result;
          } else {
            this.submittedBags.push(result);
          }
          this.submittedBags = [...this.submittedBags];

          // lets check if any packages from this bag have already been scanned
          this.packageService.checkForDuplicatePackages(result.bagId).subscribe(dupes => {
            if (dupes.length > 0) {
              let modal = this.modalService.open(DuplicatePackagesModalComponent);
              modal.componentInstance.duplicatePackages = dupes;
            }
          });
          let modal = this.modalService.open(PrintModalComponent);
          modal.componentInstance.barcodeValue = result.code;
          let paddingText = ["Bag Tag"];
          if (result.destination) {
            paddingText.push(result.destination);
          }
          modal.componentInstance.paddingText = paddingText;
        },
        (err) => {
        }
      );
      this.bag = null;
    }
    else { // open new bag
      this.bag = new Bag(destination);
    }
  }


  public addParcel() {
    let code = this.scannedParcelCode;
    if (!code) {
      return;
    }

    code = code.replace(/[^a-zA-Z0-9-_]/g, '');
    if (code === '') {
      return;
    }


    if (!this.bag.containsParcel(code)) {
      this.bag.addParcel(code);
    }

    this.scannedParcelCode = null;
  }

  public removeParcel(code) {
    if (!this.bag) {
      return;
    }
    var idx = this.bag.parcels.findIndex(x => x.code === code);
    if (idx > -1) {
      this.bag.parcels.splice(idx, 1);
    }
  }

  public onBagSelected(bag: Bag) {
    this.bag = bag;
  }

  public onPaste(val: string) {
    this.multiPackageCodesEnabled = true;

    this.scannedParcelCode = val;
  }

  public openDuplicatePackagesModal(content) {
    this.modalService.open(content)
  }
}
