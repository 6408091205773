export class FlatReportModel {
  public parcelId: string;
  public parcelCode: string;
  public bagId: string;
  public bagCode: string;
  public bagCreatedBy: string;
  public bagCreatedOn: Date;
  public get bagCreatedOnFormatted() { return this.formatDate(this.bagCreatedOn); }
  public gaylordId: string;
  public gaylordCode: string;
  public gaylordCreatedOn: Date;
  public get gaylordCreatedOnFormatted() { return this.formatDate(this.gaylordCreatedOn); }
  public gaylordCreatedBy: string;
  public gaylordVendor: string;
  public tripId: string;
  public tripName: string;
  public tripCreatedOn: Date;
  public get tripCreatedOnFormatted() { return this.formatDate(this.tripCreatedOn); }
  public tripCreatedBy: string;
  public tripShippedOn: Date;
  public get tripShippedOnFormatted() { return this.formatDate(this.tripCreatedOn); }
  public truckId: string;
  public truckName: string;
  public truckLicensePlate: string;

  private formatDate(inputDate: Date) {
    if (!inputDate) {
      return null;
    }

    return inputDate.getUTCFullYear() + "/" +
      ("0" + (inputDate.getUTCMonth() + 1)).slice(-2) + "/" +
      ("0" + inputDate.getUTCDate()).slice(-2) + " " +
      ("0" + inputDate.getUTCHours()).slice(-2) + ":" +
      ("0" + inputDate.getUTCMinutes()).slice(-2) + ":" +
      ("0" + inputDate.getUTCSeconds()).slice(-2);
  }

  public static createFromData(data: FlatReportModel) {
    let result = new FlatReportModel();
    Object.assign(result, data);

    result.bagCreatedOn = new Date(result.bagCreatedOn);
    result.gaylordCreatedOn = new Date(result.bagCreatedOn);
    result.tripCreatedOn = new Date(result.tripCreatedOn);
    result.tripShippedOn = new Date(result.tripShippedOn);

    return result;
  }
}

export class FlatReportRequest {
  public searchTerm: string;
  public fromDate: Date;
  public toDate: Date;

  public get isValid() {
    return !!this.searchTerm || !!this.fromDate;
  }
}

export class RangeCalendarMinMaxDates {
  public minDate: Date;
  public maxDate: Date;
  public resultDates: Date[];

  constructor() {
    this.minDate = new Date(2018, 8, 1);
    this.maxDate = new Date();
  }
}
