import { Component, OnInit } from '@angular/core';
import { ReportingService } from '../reporting.service';
import { FlatReportRequest, FlatReportModel, RangeCalendarMinMaxDates } from './flat-report.models';



@Component({
  selector: 'app-flat-report',
  templateUrl: './flat-report.component.html',
  styleUrls: ['./flat-report.component.css']
})
export class FlatReportComponent implements OnInit {
  public calendarInfo: RangeCalendarMinMaxDates;
  public searchRequest: FlatReportRequest;
  public reportData: FlatReportModel[];
  public inProgress = false;

  public columnMap = [
    { field: 'bagCode', header: 'Code' },
    { field: 'bagCreatedBy', header: 'Created By' },
    { field: 'bagCreatedOnFormatted', header: 'Created On' },
    { field: 'gaylordCode', header: 'Code' },
    { field: 'gaylordVendor', header: 'Vendor' },
    { field: 'gaylordCreatedOnFormatted', header: 'Created On' },
    { field: 'gaylordCreatedBy', header: 'Created By' },
    { field: 'tripName', header: 'Name' },
    { field: 'tripShippedOnFormatted', header: 'Shipped On' },
    { field: 'tripCreatedOnFormatted', header: 'Created On' },
    { field: 'tripCreatedBy', header: 'Created By' },
  ];


  constructor(private reportingService: ReportingService) {
    this.searchRequest = new FlatReportRequest();

    this.calendarInfo = new RangeCalendarMinMaxDates();
  }

  ngOnInit() {
  }


  public search() {
    if (this.calendarInfo.resultDates) {
      this.searchRequest.fromDate = this.calendarInfo.resultDates[0];
      this.searchRequest.toDate = this.calendarInfo.resultDates[1];
    }

    this.inProgress = true;
    this.reportingService.getFlatReport(this.searchRequest).subscribe(
      results => {
        this.reportData = results;
      },
      (err) => {
        console.warn(err);
        // TODO display error
      },
      () => {
        this.inProgress = false;
      }
    );
  }
}
