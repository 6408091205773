export class SearchResponse {
  constructor(searchResponseObj: SearchResponse) {
    this.packageCode = searchResponseObj.packageCode;
    this.bagCode = searchResponseObj.bagCode;
    this.gaylordCode = searchResponseObj.gaylordCode;
    this.tripName = searchResponseObj.tripName;
    this.truckName = searchResponseObj.truckName;
    this.scnCode = searchResponseObj.scnCode;
    this.wizmoSCN = searchResponseObj.wizmoSCN;
    this.shipper = searchResponseObj.shipper;
    this.receiver = searchResponseObj.receiver;
    this.shippedOn = searchResponseObj.shippedOn ? new Date(searchResponseObj.shippedOn) : null;
    this.id = this.packageCode + '/' + this.bagCode + '/' + this.gaylordCode;
  }

  public packageCode: string;
  public bagCode: string;
  public gaylordCode: string;
  public id: string;
  public tripName: string;
  public truckName: string;
  public shippedOn: Date;
  public scnCode: string;
  public wizmoSCN: string;
  public shipper: string;
  public receiver: string;
  public get shippedOnFormatted() {
    return this.shippedOn ?
      this.shippedOn.getMonth().toString().padStart(2, '0') + '/' +
      this.shippedOn.getDate().toString().padStart(2, '0') + '/' +
      this.shippedOn.getFullYear()
      : '';
  }
}


export class SearchRequest {
  public packageCodes: string[]
  public searchInTrips: string[];
  public fromDate: string;
}

export class TripModel {
  public tripId: string;
  public name: string;
  public createdOn: Date;
}
