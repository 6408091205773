import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlatReportComponent } from './flat-report/flat-report.component';
import { ReportsContainerComponent } from './reports-container/reports-container.component';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ParcelCountsComponent } from './parcel-counts/parcel-counts.component';
import { RouterModule } from '@angular/router';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CustomerManagementModule } from '../admin/customer-management/customer-management.module';

@NgModule({
  declarations: [FlatReportComponent, ReportsContainerComponent, ParcelCountsComponent],
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    CalendarModule,
    InputTextModule,
    ButtonModule,
    RouterModule,
    ChartModule,
    DropdownModule,
    CustomerManagementModule,
    MultiSelectModule
  ],
  exports: [
    ReportsContainerComponent
  ]
})
export class ReportingModule { }
