import { Component } from '@angular/core';
import { AuthService } from "../auth/auth.service";
import { UserRoles } from "../auth/auth.models";

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  constructor(private auth: AuthService) { }

  public get isAdmin() {
    return this.auth.isLoggedIn && this.auth.currentUser.roles.includes(UserRoles.admin);
  }

  public get isCustomer() {
    return this.auth.isLoggedIn && this.auth.currentUser.roles.includes(UserRoles.customer) || this.isAdmin;
  }

  public get isDriver() {
    return this.auth.isLoggedIn && this.auth.currentUser.roles.includes(UserRoles.driver) || this.isAdmin;
  }

  public get isWizmo() {
    return this.auth.isLoggedIn && this.auth.currentUser.roles.includes(UserRoles.wizmo) || this.isAdmin;
  }


  public get isLoggedIn() {
    return this.auth.isLoggedIn;
  }
    
  public logout() {
    this.auth.logout();
  }
}
