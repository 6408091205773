import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DuplicatePackagesModalComponent } from './duplicate-packages-modal/duplicate-packages-modal.component';

@NgModule({
  declarations: [DuplicatePackagesModalComponent],
  imports: [
    CommonModule
  ],
  exports: [
  ]
})
export class CommonUiModule { }
