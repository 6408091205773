import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrucksComponent } from './trucks/trucks.component';
import { FormsModule } from '@angular/forms';
import { TrucksService } from './trucks.service';

@NgModule({
  declarations: [TrucksComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
  ],
  providers: [
    TrucksService
  ]
})
export class TrucksManagementModule { }
