export class Parcel {
  parcelId: string;
  code: string;

  constructor(code: string) {
    this.code = code;
  }
}

export class Bag {
  bagId: string;
  destination: string;
  code: string;
  parcels: Parcel[];

  constructor(destination?: string) {
    this.parcels = [];
    if (!!destination) {
      this.destination = destination;
    }
  }

  addParcel(parcelId: string) {
    let parcel = new Parcel(parcelId);
    this.parcels.splice(0, 0, parcel);
  }

  containsParcel(parcelCode: string) {
    return !!this.parcels.find(x => x.code === parcelCode);
  }
}

export class DuplicateParcelsModel {
  public parcelId: string;
  public parcelCode: string;
  public bagId: string;
  public bagCode: string;
  public bagCreatedBy: string;
  public bagCreatedOn: Date;
}

