export class BarChartDataset {
  public label: string;
  public backgroundColor: string;
  public borderColor: string;
  public data: number[];
}

export class BarChartData {
  public labels: string[] = [];
  public datasets: BarChartDataset[] = [];
}
