import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Truck } from './trucks-models';

@Injectable({
  providedIn: 'root'
})
export class TrucksService {
  private serviceApi = '/api/trucks/';

  constructor(private http: HttpClient) { }

  public createTruck(truck: Truck) {
    return this.http.post<Truck>(this.serviceApi, truck);
  }

  public getTrucks() {
    return this.http.get<Truck[]>(this.serviceApi);
  }

  public updateTruck(truck: Truck) {
    return this.http.put<void>(this.serviceApi + truck.truckId, truck);
  }
}
