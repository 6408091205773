import { Bag } from '../package-input/package-input.models';

export class Gaylord {
  public gaylordId: string;
  public code: string;
  public vendor: string;
  public bagCodes: string[];
  public createdOn: Date;
  public createdBy: string;

  constructor() {
    this.bagCodes = [];
  }

  public addBag(bagCode: string) {
    this.bagCodes.splice(0, 0, bagCode);
  }

  public containsBag(bagCode: string): boolean {
    return (!!this.bagCodes && !!this.bagCodes.includes(bagCode));
  }
}
