import { Component, OnInit, OnChanges, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Gaylord } from '../gaylord-loading/gaylord-loading.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintModalComponent } from '../print-modal/print-modal.component';
import { Sort, SortDirection } from '../app.models';


@Component({
  selector: 'app-gaylord-list',
  templateUrl: './gaylord-list.component.html',
  styleUrls: ['./gaylord-list.component.css']
})
export class GaylordListComponent implements OnInit, OnChanges {

  @Input()
  public gaylords: Gaylord[] = [];

  @Output()
  public gaylordSelected = new EventEmitter<Gaylord>();

  public filteredGaylords: Gaylord[] = [];
  public filterValue: string;

  private currentSort = new Sort('code', SortDirection.Desc);

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
    if (!this.gaylords) {
      this.gaylords = [];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filteredGaylords = this.filterGaylords(this.gaylords, this.filterValue);
    if (!!this.filteredGaylords) {
      this.sortBy('createdOn', false);
    }
  }

  public onFilterGaylords() {
    this.filteredGaylords = this.filterGaylords(this.gaylords, this.filterValue);
  }

  public selectGaylord(gaylord: Gaylord) {
    this.gaylordSelected.emit(gaylord);
  }

  public print(gaylord: Gaylord) {
    var modal = this.modalService.open(PrintModalComponent);
    modal.componentInstance.barcodeValue = gaylord.code;
    let paddingText = ["Gaylord Tag"];
    if (!!gaylord.vendor) {
      paddingText.push(gaylord.vendor);
    }
    modal.componentInstance.paddingText = paddingText;
  }

  private filterGaylords(gaylords: Gaylord[], filterValue: string) {
    if (!filterValue) {
      return gaylords;
    }

    return gaylords.filter(x => !!x.bagCodes.find(c => c.includes(this.filterValue)) || x.code.includes(this.filterValue));
  }

  public sortBy(property: string, flipDirection: boolean) {
    this.currentSort = new Sort(property, this.currentSort.direction);
    if (flipDirection) {
      this.currentSort.direction *= -1;
    }

    switch (property) {
      case 'code':
        this.filteredGaylords = this.filteredGaylords.sort((a, b) => (a.code.localeCompare(b.code) * this.currentSort.direction));
        break;

      case 'bagCount':
        this.filteredGaylords = this.filteredGaylords.sort((a, b) => (a.bagCodes.length - b.bagCodes.length) * this.currentSort.direction);
        break;

      case 'createdOn':
        this.filteredGaylords = this.filteredGaylords.sort((a, b) => (a.createdOn.getTime() - b.createdOn.getTime()) * this.currentSort.direction);
        break;

      default:
        console.error('Dont know how to sort by ' + property);
        break;
    }
  }

}
