import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminContainerComponent } from './admin-container/admin-container.component';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { ReportingModule } from '../reporting/reporting.module';
import { CustomersService } from './customer-management/customers-service.service';

@NgModule({
  declarations: [AdminContainerComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReportingModule
  ]
})
export class AdminModule { }
