export class Customer {
  public customerId: string;
  public name: string;
}


export class CreateCustomerModel {
  constructor(public name) { }
}

