import { Component, OnInit, Input } from '@angular/core';
import { GaylordLoadingService } from './gaylord-loading.service';
import { Gaylord } from './gaylord-loading.models';
import { PrintModalComponent } from '../print-modal/print-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VendorsService } from './vendors.service';
import { retry } from 'rxjs/operators';

@Component({
  selector: 'app-gaylord-loading',
  templateUrl: './gaylord-loading.component.html',
  styleUrls: ['./gaylord-loading.component.css']
})
export class GaylordLoadingComponent implements OnInit {
  public gaylord: Gaylord;
  public vendors: string[];
  public duplicateBagFound = false;
  public submittedGaylords: Gaylord[];

  @Input()
  scannedBagCode: string;


  constructor(private gaylordService: GaylordLoadingService, private vendorService: VendorsService, private modalService: NgbModal) { }

  ngOnInit() {
    this.gaylordService.getGaylords(true).subscribe(g => {
      this.submittedGaylords = g;
    });

    this.vendorService.getVendors().subscribe(v => {
      this.vendors = v;
    });
  }

  generateGaylord(vendor: string) {
    if (!!this.gaylord) {
      this.gaylordService.saveGaylord(this.gaylord).subscribe(
        (result) => {
          // new or updated bag here
          let idx = this.submittedGaylords.findIndex(x => x.gaylordId === result.gaylordId);
          if (idx > -1) {
            this.submittedGaylords[idx] = result;
          } else {
            this.submittedGaylords.push(result);
          }
          this.submittedGaylords = [...this.submittedGaylords];

          var modal = this.modalService.open(PrintModalComponent);
          modal.componentInstance.barcodeValue = result.code;
          let paddingText = ["Gaylord Tag"];
          if (!!result.vendor) {
            paddingText.push(result.vendor);
          }
          modal.componentInstance.paddingText = paddingText;
        },
        (err) => {

        }
      );
      this.gaylord = null;
    } else {
      this.gaylord = new Gaylord();
      this.gaylord.vendor = vendor;
    }
  }

  addBag() {
    if (!this.gaylord) {
      return;
    }

    let code = this.scannedBagCode;
    if (!code) {
      return;
    }
    code = code.replace(/[^0-9-]/g, '');
    if (code === '') {
      return;
    }

    if (this.gaylord.containsBag(code)) {
      this.duplicateBagFound = true;
      return;
    }

    this.duplicateBagFound = false;

    this.gaylord.addBag(code);
    this.scannedBagCode = null;
  }



  public removeBag(code) {
    if (!this.gaylord) {
      return;
    }

    let idx = this.gaylord.bagCodes.findIndex(x => x === code);
    if (idx > -1) {
      this.gaylord.bagCodes.splice(idx, 1);
    }
  }

  public onGaylordSelected(gaylord: Gaylord) {
    this.gaylord = gaylord;
  }
}
