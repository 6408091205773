import { Component, OnInit } from '@angular/core';
import { AuthService } from "../auth.service";
import { Router } from '@angular/router';
import { UserRoles } from '../auth.models';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public invalidLogin: boolean;
  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
  }

  public onLogin(username, password) {
    this.invalidLogin = false;
    this.auth.login(username, password).subscribe(resp => {


      if (this.auth.currentUser.roles.includes(UserRoles.admin)) {
        this.router.navigate(['']);
      }
      else if (this.auth.currentUser.roles.includes(UserRoles.wizmo)) {
        this.router.navigate(['wizmo']);
      }
      else if (this.auth.currentUser.roles.includes(UserRoles.driver)) {
        this.router.navigate(['search']);
      }
      else if (this.auth.currentUser.roles.includes(UserRoles.customer)) {
        this.router.navigate(['customer']);
      }
    }, err => {
      this.invalidLogin = true;
    });
  }
}
