import { Component, OnInit } from '@angular/core';
import { ReportingService } from '../reporting.service';
import { CustomersService } from '../../admin/customer-management/customers-service.service';
import { Filter, ParcelCountsReportRequest, TimePeriods, FilterSeedData } from './parcel-counts.models';
import { BarChartData, BarChartDataset } from '../reporting.models';

@Component({
  selector: 'app-parcel-counts',
  templateUrl: './parcel-counts.component.html',
  styleUrls: ['./parcel-counts.component.css']
})
export class ParcelCountsComponent implements OnInit {
  private colors = ['#93E986', '#8C623E', '#1A6B32', '#3E898C', '#3E8C74', '#3E498C', '#1FA47C', '#FE6601', '#8C3E65', '#ACD895', '#8C3E84', '#2AFE94', '#1A6B66', '#418C3E', '#8C813E', '#95D8C0', '#AE95D8', '#5D8C3E', '#3E8C5B', '#FECF10', '#6B1A35', '#773E8C', '#95D8A3', '#57875A', '#586B1A', '#95B7D8', '#D89595', '#563E8C', '#D895D1', '#D81CED', '#4DFE01', '#7A8C3E', '#FE1038', '#8C3E3E', '#959ED8', '#D895B2', '#3E6D8C', '#D8C095', '#CFD895', '#2ABFFE', '#95D6D8', '#D8A795', '#2301FE', '#3EA41F', '#2AFE38', '#BBFE01', '#851CED', '#2AFEE2', '#FEC301', '#1A1D6B', '#FE0901', '#C695D8', '#FE7F10', '#ED1C85', '#2A62FE'];
  constructor(private reportingService: ReportingService, private customersService: CustomersService) { }

  public chartData = new BarChartData();
  public chartDataReady = false;

  // populates the filter
  public filterSeedData = new FilterSeedData();

  public filter = new Filter();
   
  data: any;

  ngOnInit() {
    this.loadCustomers();
  }


  public getReportingData() {
    var reportingRequest = new ParcelCountsReportRequest();
    reportingRequest.datePart = this.filter.datePart;
    if (!!this.filter.dateRange) {
      reportingRequest.fromDate = this.filter.dateRange[0];
      reportingRequest.toDate = this.filter.dateRange[1];
    }
    reportingRequest.customers = this.filter.customers;

    this.reportingService.getParcelCountsPerPeriodPerCustomerReport(reportingRequest).subscribe(reportData => {
      this.chartData = new BarChartData();
      let customerData: any = {};
      let colorIdx = 0;

      let allDates: any = {};
      reportData.map(x => { allDates[x.dateFormatted] = 0; })
      this.chartData.labels = Object.keys(allDates);


      reportData.forEach(r => {
        if (!customerData[r.customerName]) {
          customerData[r.customerName] = Object.assign({}, allDates);
        }

        customerData[r.customerName][r.dateFormatted] = r.packageCount; // we'll have {'wizmo':{'1-1-2019':111, '2-1-2019':90}}
      });

      let customerNames = Object.keys(customerData);

      customerNames.forEach(c => {
        let dataset = new BarChartDataset();
        dataset.label = c;
        dataset.backgroundColor = this.colors[colorIdx++];
        dataset.data = Object.keys(customerData[c]).map(x => customerData[c][x]);
        this.chartData.datasets.push(dataset);
      });

      this.chartDataReady = true;
    });
  }


  private loadCustomers() {
    this.customersService.getCustomers().subscribe(x => {
      this.filterSeedData.customersRaw = x;
    });
  }
}
