import { Component, OnInit } from '@angular/core';
import { Truck } from '../trucks-models';
import { TrucksService } from '../trucks.service';

@Component({
  selector: 'app-trucks',
  templateUrl: './trucks.component.html',
  styleUrls: ['./trucks.component.css']
})
export class TrucksComponent implements OnInit {
  public trucks: Truck[] = [];
  public currentTruck = new Truck();

  constructor(private trucksService: TrucksService) { }

  ngOnInit() {
    this.trucksService.getTrucks().subscribe(x => this.trucks = x);
  }


  public addTruck() {
    this.trucksService.createTruck(this.currentTruck).subscribe(x => {
      this.trucks.push(x);
      this.currentTruck = new Truck();
    });
  }

  public prepTruckForUpdate(truck: Truck) {
    let truck2 = Truck.clone(truck);
    this.currentTruck = truck2;
  }

  public updateTruck() {
    this.trucksService.updateTruck(this.currentTruck).subscribe(() => {
      let idx = this.trucks.findIndex(x => x.truckId === this.currentTruck.truckId);
      this.trucks.splice(idx, 1, this.currentTruck);
      this.currentTruck = new Truck();
    });
  }

  public resetTruck() {
    this.currentTruck = new Truck();
  }
}
