import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { PackageInputComponent } from './package-input/package-input.component';
import { GaylordLoadingComponent } from './gaylord-loading/gaylord-loading.component';
import { CalendarModule } from 'primeng/calendar';
import { PackageSearchComponent } from './package-search/package-search.component';
import { AuthModule } from './auth/auth.module';
import { LoginComponent } from './auth/login/login.component';
import { AuthService } from './auth/auth.service';
import { AuthInterceptor } from './auth/auth-interceptor';
import { BagListComponent } from './bag-list/bag-list.component';
import { GaylordListComponent } from './gaylord-list/gaylord-list.component';
import { PrintModalComponent } from './print-modal/print-modal.component';
import { UserManagerComponent } from './admin/user-management/user-manager/user-manager.component';
import { ResetPasswordComponent } from './admin/user-management/reset-password/reset-password.component';
import { UserManagementModule } from './admin/user-management/user-management.module';
import { ForgotPasswordComponent } from './admin/user-management/forgot-password/forgot-password.component';
import { IsLoggedInGuard } from './auth/guards/is-logged-in.guard';
import { IsAdminGuard } from './auth/guards/is-admin.guard';
import { ReverseLookupComponent } from './reverse-lookup/reverse-lookup.component';
import { AdminContainerComponent } from './admin/admin-container/admin-container.component';
import { TrucksComponent } from './admin/trucks-management/trucks/trucks.component';
import { AdminModule } from './admin/admin.module';
import { TrucksManagementModule } from './admin/trucks-management/trucks-management.module';
import { TripsModule } from './trips/trips.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { IsInRoleGuard } from './auth/guards/is-in-role.guard';
import { TripsListComponent } from './trips/trips-list/trips-list.component';
import { ReportsContainerComponent } from './reporting/reports-container/reports-container.component';
import { WorkflowModule } from './workflow/workflow.module';
import { DuplicatePackagesModalComponent } from './workflow/common-ui/duplicate-packages-modal/duplicate-packages-modal.component';
import { CustomersManagerComponent } from './admin/customer-management/customers-manager/customers-manager.component';
import { CustomerManagementModule } from './admin/customer-management/customer-management.module';
import { FlatReportComponent } from './reporting/flat-report/flat-report.component';
import { ParcelCountsComponent } from './reporting/parcel-counts/parcel-counts.component';
import { IntercomModule } from './intercom/intercom.module';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    PackageInputComponent,
    GaylordLoadingComponent,
    PackageSearchComponent,
    BagListComponent,
    GaylordListComponent,
    PrintModalComponent,
    ReverseLookupComponent
  ],
  entryComponents: [
    PrintModalComponent,
    DuplicatePackagesModalComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AngularFontAwesomeModule,
    NgbModule.forRoot(),
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'customer', component: PackageInputComponent, canActivate: [IsLoggedInGuard] },
      { path: 'wizmo', component: GaylordLoadingComponent, canActivate: [IsLoggedInGuard] },
      { path: 'trips', component: TripsListComponent, canActivate: [IsLoggedInGuard, IsInRoleGuard], data: { roles: ['admin', 'wizmo'] } },
      { path: 'search', component: PackageSearchComponent, canActivate: [IsLoggedInGuard] },
      { path: 'login', component: LoginComponent },
      { path: 'password-reset', component: ResetPasswordComponent },
      { path: 'password-restore', component: ForgotPasswordComponent },
      { path: 'reverse-lookup', component: ReverseLookupComponent, canActivate: [IsLoggedInGuard] },
      {
        path: 'admin', component: AdminContainerComponent, canActivate: [IsAdminGuard],
        children: [
          { path: 'users', component: UserManagerComponent, canActivate: [IsLoggedInGuard, IsAdminGuard] },
          { path: 'customers', component: CustomersManagerComponent, canActivate: [IsLoggedInGuard, IsAdminGuard] },
          { path: 'trucks', component: TrucksComponent, canActivate: [IsLoggedInGuard, IsAdminGuard] },
          {
            path: 'reporting', component: ReportsContainerComponent, canActivate: [IsLoggedInGuard, IsAdminGuard],
            children: [
              { path: 'flat-report', component: FlatReportComponent, canActivate: [IsLoggedInGuard, IsAdminGuard] },
              { path: 'parcel-counts', component: ParcelCountsComponent, canActivate: [IsLoggedInGuard, IsAdminGuard] }
            ]
          }
        ]
      }
    ]),
    AuthModule,
    UserManagementModule,
    AdminModule,
    TrucksManagementModule,
    TripsModule,
    WorkflowModule,
    CustomerManagementModule,
    IntercomModule,
    CalendarModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private auth: AuthService) {
  }
}
