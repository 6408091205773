import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {

  private serviceApi = '/api/vendors';

  constructor(private http: HttpClient) { }

  public getVendors() {
    return this.http.get<string[]>(this.serviceApi);
  }
}
