import { Component, OnInit } from '@angular/core';
import { PackageSearchService } from './package-search.service';
import { SearchResponse, TripModel, SearchRequest } from './package-search.models';
import { TripsService } from '../trips/trips.service';

@Component({
  selector: 'app-package-search',
  templateUrl: './package-search.component.html',
  styleUrls: ['./package-search.component.css']
})
export class PackageSearchComponent implements OnInit {
  public searchResults: SearchResponse[];
  public foundBoxes: any = {};
  public trips: TripModel[];
  public fromDate: Date;

  constructor(private searchService: PackageSearchService, private tripService: TripsService) { }

  ngOnInit() {
    this.tripService.getTrips(true).subscribe(trips => {
      let ts = trips.map(trip => {
        let t = new TripModel();
        t.tripId = trip.tripId;
        t.name = trip.name;
        t.createdOn = new Date(trip.createdOn);
        return t;
      });

      this.trips = ts.sort((a, b) => a.createdOn.valueOf() - b.createdOn.valueOf());
    })

    // default fromDate to now - 5 days
    this.fromDate = new Date();
    this.fromDate.setDate(this.fromDate.getDate() - 5);
  }


  public search(values: string, trip: TripModel) {
    let vals = values.split(/[,\.\n ]/);
    vals.forEach((x, i, a) => { if (!!x) { a[i] = x.trim(); } }); // trim all
    vals = vals.filter(x => !!x);

    this.searchResults = null;
    var req = new SearchRequest();
    req.packageCodes = vals;
    if (!!trip) {
      req.searchInTrips = [trip.tripId];
    }

    // fromDate has time set to 4am for whatever reason. Clear this
    req.fromDate = (this.fromDate.getMonth() + 1) + '/' + this.fromDate.getDate() + '/' + this.fromDate.getFullYear();

    this.searchService.search(req).subscribe(found => {
      let results = found.map(x => new SearchResponse(x));
      this.foundBoxes = {};
      results.forEach(b => {
        this.foundBoxes[b.id] = false;
      });
      results.sort((a, b) => a.bagCode.localeCompare(b.bagCode));
      this.searchResults = this.matchFoundToInput(vals, results);
    });
  }

  public toggleFound(result: SearchResponse) {
    this.foundBoxes[result.id] = !this.foundBoxes[result.id];
  }

  private matchFoundToInput(searchedValues: string[], searchResponses: SearchResponse[]): SearchResponse[] {

    let result: SearchResponse[] = [];
    let dupes: any = {};

    searchedValues.forEach((v) => {
      let val = v.toLocaleLowerCase();
      searchResponses.forEach((r) => {
        if ((r.packageCode.toLocaleLowerCase().includes(val) || (!!r.wizmoSCN && r.wizmoSCN.toLocaleLowerCase().includes(val))) && !dupes[r.id]) {
          result.push(r);
          dupes[r.id] = true;
        }
      });
    });

    return result;
  }
}
