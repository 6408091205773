import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BarcodeService {
  private serviceApi = '/api/barcodes';

  constructor(private http: HttpClient) { }

  public getBarcode(value: string, paddingText: string[] | string): Observable<Blob> {
    let params: any = { value: value };
    if (!!paddingText) {
      if (Array.isArray(paddingText)) {
        params.paddingText = paddingText;
      }
      else {
        params.paddingText = [paddingText];
      }
    };

    return this.http.get(this.serviceApi, { params: params, responseType: 'blob' });
  }

}
