import { Component, OnInit } from '@angular/core';
import { Truck } from '../../admin/trucks-management/trucks-models';
import { TripViewModel, Trip } from '../trips-models';
import { TrucksService } from '../../admin/trucks-management/trucks.service';
import { TripsService } from '../trips.service';
import { Gaylord } from '../../gaylord-loading/gaylord-loading.models';
import { SortDirection, Sort } from '../../app.models';

@Component({
  selector: 'app-trips-list',
  templateUrl: './trips-list.component.html',
  styleUrls: ['./trips-list.component.css']
})
export class TripsListComponent implements OnInit {
  public trucks: Truck[];
  public trip: TripViewModel;
  public trips: TripViewModel[] = [];
  public filteredTrips: TripViewModel[] = [];

  public filterValue: string;

  public selectedTruck: Truck;
  public scannedGaylordCode: string;
  public currentSort = new Sort('name', SortDirection.Desc);

  constructor(private trucksService: TrucksService, private tripService: TripsService) { }

  ngOnInit() {
    this.trucksService.getTrucks().subscribe(x => {
      this.trucks = x;
    });

    this.tripService.getTrips().subscribe(x => {
      this.trips = x;
      this.trips.sort((a, b) => new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf());
      this.filteredTrips = this.trips;
    });
  }

  public generateTrip() {
    if (!this.trip) {
      this.createNewTrip();
    }
    else {
      this.finalizeTrip();
    }
  }

  public addGaylord() {
    if (!this.scannedGaylordCode) {
      return;
    }
    if (!this.trip.gaylords.find(x => x.code === this.scannedGaylordCode)) {
      let gaylord = new Gaylord();
      gaylord.code = this.scannedGaylordCode;
      this.trip.gaylords.splice(0, 0, gaylord);
    }

    this.scannedGaylordCode = null;
  }

  public removeGaylord(gaylord: Gaylord) {
    let idx = this.trip.gaylords.findIndex(x => x.code === gaylord.code);
    if (idx > -1) {
      this.trip.gaylords.splice(idx, 1);
    }
  }

  public selectTrip(trip: TripViewModel) {
    this.trip = trip;
    this.selectedTruck = trip.truck;
  }

  public trucksCompare(a: Truck, b: Truck) {
    return !!a && !!b && a.truckId == b.truckId;
  }


  public sortBy(property: string, flipDirection: boolean) {
    this.currentSort = new Sort(property, this.currentSort.direction);
    if (flipDirection) {
      this.currentSort.direction *= -1;
    }

    switch (property) {
      case 'name': {
        this.filteredTrips = this.filteredTrips.sort((a, b) => b.name.localeCompare(a.name) * this.currentSort.direction);
        break;
      }
      case 'gaylordCount': {
        this.filteredTrips = this.filteredTrips.sort((a, b) => (a.gaylords.length - b.gaylords.length) * this.currentSort.direction);
        break;
      }
    }
  }

  public onFilterGaylords() {
    this.filteredTrips = this.trips.filter(x => x.name.toLocaleLowerCase().includes(this.filterValue.toLocaleLowerCase())
      || (!!x.gaylords &&
        (x.gaylords.findIndex(g => g.code.includes(this.filterValue)) > -1
          || x.gaylords.findIndex(g => !!g.bagCodes && g.bagCodes.includes(this.filterValue)) > -1)
      )
    );
  }

  public shipTrip(trip: TripViewModel) {
    this.tripService.ship(trip).subscribe(x => {
      let idx = this.trips.findIndex(t => t.tripId == x.tripId);
      Object.assign(this.trips[idx], x);
    });
  }

  public downloadCSV(trip: TripViewModel) {
    this.tripService.downloadCSV(trip.tripId, trip.name);
  } 

  public generateManifest(trip: Trip) {
    this.tripService.generateManifest(trip);
  }

  private createNewTrip() {
    this.trip = new TripViewModel();

    let date = new Date();
    let datePart = date.getFullYear().toString() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + (date.getDate())).slice(-2) +
      '-' + date.getHours();

    this.trip.name = this.selectedTruck.name + '-' + datePart;
    this.trip.truck = this.selectedTruck;
  }

  private finalizeTrip() {
    if (!!this.trip.tripId) {
      // update
      this.trip.truck = this.selectedTruck;
      this.tripService.updateTrip(this.trip).subscribe(x => {
        // don't need to do anything, just have to subscribe to execute update
      });
    }
    else {
      // create
      this.tripService.createTrip(this.trip).subscribe(x => {
        this.trips.splice(0, 0, x);
      });
    }
    this.trip = null;
  }

  
}
