import { Customer } from "../../admin/customer-management/customers.models";

export class ParcelCountsReportRequest {
  public datePart: string;
  public fromDate: Date;
  public toDate: Date;
  public customers: Customer[];
}

export class TimePeriods {
  public static readonly Day = 'day';
  public static readonly Week = 'week';
  public static readonly Month = 'month';
  public static readonly Year = 'year';
}


export class ParcelCountsPerPeriodPerCustomerModel {
  public date: string;
  public dateFormatted: string;
  public customerName: string;
  public packageCount: number;
}


export const MinReportingDate = new Date(2018, 8, 1);


export class Filter {
  public datePart: string = TimePeriods.Day;
  public dateRange: Date[];
  public customers: Customer[];
}


export class FilterSeedData {
  public timePeriods = [{ label: 'Day', value: TimePeriods.Day }, { label: 'Week', value: TimePeriods.Week }, { label: 'Month', value: TimePeriods.Month }, { label: 'Year', value: TimePeriods.Year }];
  public minDate: Date = MinReportingDate;
  public maxDate = new Date();
  public get customersMultiSelect() {
    return this.customersRaw.map(x => { return { label: x.name, value: x }; });
  }
  public customersRaw: Customer[] = [];
};
