import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ParcelCountsReportRequest, ParcelCountsPerPeriodPerCustomerModel } from './parcel-counts/parcel-counts.models';
import { FlatReportRequest, FlatReportModel } from './flat-report/flat-report.models';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  private serviceUrl = '/api/reporting/';

  constructor(private http: HttpClient) { }

  public getFlatReport(req: FlatReportRequest) {
    return this.http.post<FlatReportModel[]>(this.serviceUrl + 'flat-report', req).pipe(map(rrs => {
      return rrs.map(r => FlatReportModel.createFromData(r));
    }));
  }

  public getParcelCountsPerPeriodPerCustomerReport(reportRequest: ParcelCountsReportRequest) {
    return this.http.post<ParcelCountsPerPeriodPerCustomerModel[]>(this.serviceUrl + 'parcel-count', reportRequest);
  }
}
