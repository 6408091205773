import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatReportModel } from '../../../reporting/flat-report/flat-report.models';

@Component({
  selector: 'app-duplicate-packages-modal',
  templateUrl: './duplicate-packages-modal.component.html',
  styleUrls: ['./duplicate-packages-modal.component.css']
})
export class DuplicatePackagesModalComponent implements OnInit {

  @Input()
  public duplicatePackages: FlatReportModel[];

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() { 
    if (!!this.duplicatePackages) {
      this.duplicatePackages = this.duplicatePackages.sort((a, b) => a.parcelCode.localeCompare(b.parcelCode));
    }
  }
}
