import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonUiModule } from './common-ui/common-ui.module'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CommonUiModule,
  ],
  exports: [
    CommonUiModule
  ]
})
export class WorkflowModule { }
