import { Component, OnInit } from '@angular/core';
import { ReverseLookupService } from './reverse-lookup.service';
import { Bag } from '../package-input/package-input.models';
import { PrintModalComponent } from '../print-modal/print-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-reverse-lookup',
  templateUrl: './reverse-lookup.component.html',
  styleUrls: ['./reverse-lookup.component.css']
})
export class ReverseLookupComponent implements OnInit {
  public bag: Bag;
  public notFound: boolean = false;

  constructor(private reverseLookupService: ReverseLookupService, private modalService: NgbModal) { }

  ngOnInit() {
  }


  public findBag(packageCode: string) {
    this.bag = null;
    this.notFound = false;
    this.reverseLookupService.findBag(packageCode).subscribe(
      x => {
        this.bag = x;
      },
      err => {
        this.notFound = true;
      });
  }

  public print(bag: Bag) {
    var modal = this.modalService.open(PrintModalComponent);
    modal.componentInstance.barcodeValue = bag.code;
    modal.componentInstance.paddingText = "Bag Tag";
  }
}
