import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Trip, TripViewModel } from './trips-models';
import { flatMap } from 'rxjs/operators';
import saveAs from 'file-saver';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TripsService {
  private tripServiceApi = '/api/trips/';
  private manifestServiceApi = 'api/manifest/';

  constructor(private http: HttpClient) { }

  public createTrip(trip: TripViewModel) {
    return this.http.post<TripViewModel>(this.tripServiceApi, trip);
  }

  public getTrips(incomplete: boolean = true): Observable<TripViewModel[]> {
    return this.http.get<TripViewModel[]>(this.tripServiceApi, { params: { "incompleteOnly": incomplete.toString() } })
      .pipe(flatMap((res: TripViewModel[]) => [res]));
  }

  public updateTrip(trip: Trip) {
    return this.http.put<void>(this.tripServiceApi + trip.tripId, trip);
  }

  public downloadCSV(tripid: string, name: string): void {
    this.http.get(this.tripServiceApi + tripid + "/csv", { responseType: 'blob' }).subscribe(data => {
      var blob = new Blob([data], { type: 'text/csv' });
      saveAs(blob, "trip-" + name + ".csv");
    });
  }

  public ship(trip: Trip) {
    return this.http.put<Trip>(this.tripServiceApi + trip.tripId + '/ship', {});
  }

  public generateManifest(trip: Trip): void {

    this.http.get(this.manifestServiceApi + trip.tripId, { responseType: 'blob' })
      .subscribe(data => {
        var blob = new Blob([data], { type: 'text/csv' });
        saveAs(blob, trip.name + "_manifest.csv");
      });
  }
}
