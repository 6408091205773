import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Bag, Parcel, DuplicateParcelsModel } from './package-input.models';
import { map } from 'rxjs/operators';
import { FlatReportModel } from '../reporting/flat-report/flat-report.models';


@Injectable({
  providedIn: 'root'
})
export class PackageInputService {
  private serviceApi = '/api/bags';

  constructor(private http: HttpClient) { }

  public saveBag(bag: Bag) {
    if (!!bag.bagId) {
      return this.http.put<Bag>(this.serviceApi, bag).pipe(map(x => this.enrichBag(x)));
    }
    return this.http.post<Bag>(this.serviceApi, bag).pipe(map(x => this.enrichBag(x)));
  }

  public getBags() {
    return this.http.get<Bag[]>(this.serviceApi).pipe(map(bags => {
      let result: Bag[] = [];
      bags.forEach(x => {
        let bag = this.enrichBag(x);
        result.push(bag);
      })
      return result;
    }));
  }

  public checkForDuplicatePackages(bagId: string) {
    let url = this.serviceApi + "/" + bagId + "/duplicates";
    return this.http.get<DuplicateParcelsModel[] | FlatReportModel[]>(url).pipe(map(dupes => {
      if (dupes.length == 0) {
        return [];
      }
      // returns DuplicateParcelsModel for customers, FlatReportModel for admins
      if ((<FlatReportModel>dupes[0]).gaylordId !== undefined) {
        let result: FlatReportModel[] = [];
        dupes.forEach((dupe: FlatReportModel) => {
          let model = new FlatReportModel();
          Object.assign(model, dupe);
          model.bagCreatedOn = new Date(dupe.bagCreatedOn);
          model.gaylordCreatedOn = !!dupe.gaylordCreatedOn ? new Date(dupe.gaylordCreatedOn) : null;
          result.push(model);
        });
        return result;
      }
      else {
        let result: DuplicateParcelsModel[] = [];
        dupes.forEach(dupe => {
          let model = new DuplicateParcelsModel();
          Object.assign(model, dupe);
          model.bagCreatedOn = new Date(dupe.bagCreatedOn);
          result.push(model);
        });
        return result;
      }
    }));
  }


  private enrichBag(bagData: Bag) {
    let bag = new Bag();
    Object.assign(bag, bagData);
    return bag;
  }
}
