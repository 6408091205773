import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserInfo } from './user-management.models';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  private usersServiceApi = '/api/users';
  private passwordServiceApi = '/api/passwordmanagement';

  constructor(private http: HttpClient) { }

  public getAllUsers() {
    return this.http.get<UserInfo[]>(this.usersServiceApi);
  }

  public create(user: UserInfo) {
    return this.http.post<UserInfo>(this.usersServiceApi, user);
  }

  public update(user: UserInfo) {
    return this.http.put<UserInfo>(this.usersServiceApi, user);
  }

  public deleteUser(user: UserInfo) {
    return this.http.delete(this.usersServiceApi, { params: { id: user.id } });
  }

  public resetPassword(token: string, userId: string, password: string) {
    let data = {
      token: token,
      userId: userId,
      password: password
    };

    return this.http.post(this.passwordServiceApi + '/password-reset', data);
  }

  sendPasswordRestoreEmail(username: string) {
    return this.http.post(this.passwordServiceApi + '/password-restore', { username: username });
  }

}
