import { Component, OnInit, EventEmitter, Output, Input, } from '@angular/core';
import { UserInfo } from '../user-management.models';
import { UserManagementService } from '../user-management.service';
import { FormsModule } from '@angular/forms';
import { CustomersService } from '../../customer-management/customers-service.service';
import { Customer } from '../../customer-management/customers.models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
  public user: UserInfo;
  public customers: Customer[];

  public flags = { isSuccess: null, error: null };

  @Input()
  public set updatingUser(user: UserInfo) {
    this.user = user;
  }

  @Output()
  public userCreatedOrUpdated = new EventEmitter<UserInfo>();

  constructor(private usersService: UserManagementService, private customersService: CustomersService) { }

  ngOnInit() {
    this.user = new UserInfo();
    this.customersService.getCustomers().subscribe(x => this.customers = x);
  }

  public save() {
    this.flags.isSuccess = null;
    this.flags.error = null;

    var userCreatedOrUpdated$: Observable<UserInfo>;

    if (!!this.user.id) {
      userCreatedOrUpdated$ = this.usersService.update(this.user);
    }
    else {
      userCreatedOrUpdated$ = this.usersService.create(this.user)
    }

    userCreatedOrUpdated$.subscribe(x => {
      this.user = new UserInfo();
      this.userCreatedOrUpdated.emit(x);
      this.flags.isSuccess = true;
    },
      err => {
        this.flags.error = err.error;
      });
  }

  public clear() {
    this.user = new UserInfo();
  }

  public customersCompare(a: Customer, b: Customer) {
    return !!a && !!b && a.customerId === b.customerId;
  }
}
