import { Injectable } from '@angular/core';
import { Bag } from '../package-input/package-input.models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReverseLookupService {
  private serviceApi = '/api/bags/reverse';

  constructor(private http: HttpClient) { }

  public findBag(packageCode: string) {
    return this.http.get<Bag>(this.serviceApi, { params: { packageCode: packageCode } });
  }
}
