import { Component, OnInit } from '@angular/core';
import { Customer } from '../customers.models';
import { CustomersService } from '../customers-service.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers-manager.component.html',
  styleUrls: ['./customers-manager.component.css']
})
export class CustomersManagerComponent implements OnInit {
  public customers: Customer[] = [];
  public currentCustomer = new Customer();

  constructor(private customersService: CustomersService) { }

  ngOnInit() {
    this.customersService.getCustomers().subscribe(x => this.customers = x);
  }

   
  public addCustomer() {
    this.customersService.createCustomer(this.currentCustomer.name).subscribe(x => {
      this.customers.push(x);
      this.currentCustomer = new Customer();
    });
  }

  public updateCustomer() {
    this.customersService.updateCustomer(this.currentCustomer).subscribe(() => {
      let idx = this.customers.findIndex(x => x.customerId === this.currentCustomer.customerId);
      this.customers.splice(idx, 1, this.currentCustomer);
      this.currentCustomer = new Customer();
    });
  }

  public resetCustomer() {
    this.currentCustomer = new Customer();
  }
}
