import { Truck } from "../admin/trucks-management/trucks-models";
import { Gaylord } from "../gaylord-loading/gaylord-loading.models";

export class Trip {
  public tripId: string;
  public date: string;
  public name: string;
  public shippedOn: Date;
  public createdOn: string;
  public createdBy: string;
  public truck: Truck;
}


export class TripViewModel extends Trip {
  public gaylords: Gaylord[] = [];
}


export class CreateTripModel {
  public name: string;
  public truck: Truck;
  public gaylordCodes: string[] = [];

}
