import { Component, OnInit, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserInfo } from '../user-management.models';
import { UserManagementService } from '../user-management.service';
import { CustomersService } from '../../customer-management/customers-service.service';
import { forkJoin } from 'rxjs';
import { Customer } from '../../customer-management/customers.models';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {

  public users: UserInfo[];
  public customers: Customer[];

  @Input()
  public addedUser: UserInfo = null;


  @Output()
  public userSelectedForUpdate = new EventEmitter<UserInfo>();


  constructor(private usersService: UserManagementService, private customersService: CustomersService, private modalService: NgbModal) { }

  ngOnInit() {
    this.loadUsersWithCustomers();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadUsersWithCustomers();
  }

  public delete(modalContent, user: UserInfo) {
    this.modalService.open(modalContent).result.then((result) => {
      this.usersService.deleteUser(user).subscribe(x => {
        let idx = this.users.findIndex(x => x.id == user.id);
        this.users.splice(idx, 1);
      });
    }, (reason) => {
      console.log(reason);
    });
  }

  public create(userName: string, email: string, role: string) {
    let user = new UserInfo();
    user.userName = userName;
    user.email = email;
    user.role = role;
    this.usersService.create(user).subscribe(x => {
      this.users.push(x);
    });
  }

  public loadUsersWithCustomers() {
    const userLoader$ = this.usersService.getAllUsers();
    const customersLoader$ = this.customersService.getCustomers();
    forkJoin(userLoader$, customersLoader$).subscribe(result => {
      // populate users with customer data
      let users = result[0];
      let customers = result[1];

      users = users.map(user => {
        if (!!user.customerId) {
          let customer = customers.find(c => c.customerId === user.customerId);
          user.customer = customer;
        }
        return Object.assign(new UserInfo(), user);
      });

      this.users = users;
      this.customers = customers;
    });
  }


  public onUserCustomerChange(val) {
    console.log("user customer change", val);
  }


  public selectUser(user: UserInfo) {
    console.log("selected", user);
    this.userSelectedForUpdate.emit(user);
  }
}
