import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Gaylord } from './gaylord-loading.models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GaylordLoadingService {

  private serviceApi = '/api/gaylords';

  constructor(private http: HttpClient) { }

  saveGaylord(gaylord: Gaylord) {
    if (!!gaylord.gaylordId) {
      return this.http.put<Gaylord>(this.serviceApi, gaylord).pipe(map(x => this.enrichGaylord(x)));
    }
    return this.http.post<Gaylord>(this.serviceApi, gaylord).pipe(map(x => this.enrichGaylord(x)));
  }

  getGaylords(unassignedToTrip: boolean = null) {
    let params: any = {};
    if (unassignedToTrip !== null) {
      params.unassignedToTrip = unassignedToTrip;
    }

    return this.http.get<Gaylord[]>(this.serviceApi, { params }).pipe(map(gaylords => {
      let result: Gaylord[] = [];
      gaylords.forEach(x => {
        let gaylord = this.enrichGaylord(x);
        result.push(gaylord);
      })
      return result;
    }));
  }


  private enrichGaylord(gaylordData: Gaylord) {
    let g = new Gaylord();
    Object.assign(g, gaylordData);
    g.createdOn = new Date(gaylordData.createdOn);
    return g;
  }
}
