import { Component, OnInit, OnChanges, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Bag } from '../package-input/package-input.models';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PrintModalComponent } from '../print-modal/print-modal.component';
import { Sort, SortDirection } from '../app.models';

@Component({
  selector: 'app-bag-list',
  templateUrl: './bag-list.component.html',
  styleUrls: ['./bag-list.component.css'],
  entryComponents: [PrintModalComponent]
})
export class BagListComponent implements OnInit, OnChanges {

  @Input()
  public bags: Bag[] = [];

  @Output()
  public bagSelected = new EventEmitter<Bag>();

  public filterValue: string;

  public filteredBags: Bag[] = [];

  public currentSort = new Sort('code', SortDirection.Desc);

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
    if (!this.bags) {
      this.bags = [];
    }

    this.filteredBags = this.bags;
    this.sortBy('code', false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!this.bags) {
      this.filteredBags = this.filterBags(this.bags, this.filterValue);
      this.sortBy('code', false);
    }
  }

  public onFilterBags() {
    this.filteredBags = this.filterBags(this.bags, this.filterValue);
  }

  private filterBags(bags: Bag[], filterValue: string) {
    if (!filterValue) {
      return bags;
    }

    let fvl = filterValue.toLocaleLowerCase();
    return bags.filter(x => !!x.parcels.find(p => p.code.toLocaleLowerCase().includes(fvl)) || x.code.toLocaleLowerCase().includes(fvl));
  }

  public selectBag(bag: Bag) {
    this.bagSelected.emit(bag);
  }

  public print(bag: Bag) {
    var modal = this.modalService.open(PrintModalComponent);
    modal.componentInstance.barcodeValue = bag.code;
    modal.componentInstance.paddingText = "Bag Tag";
  }

  public sortBy(property: string, flipDirection: boolean) {
    this.currentSort = new Sort(property, this.currentSort.direction);
    if (flipDirection) {
      this.currentSort.direction *= -1;
    }

    switch (property) {
      case 'code': {
        this.filteredBags = this.filteredBags.sort((b, a) => (b.code.localeCompare(a.code)) * this.currentSort.direction);
        break;
      }
      case 'packageCount': {
        this.filteredBags = this.filteredBags.sort((a, b) => (a.parcels.length - b.parcels.length) * this.currentSort.direction);
        break;
      }
    }
  }
}
