import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Customer, CreateCustomerModel } from './customers.models';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  private serviceApi = '/api/customers/';

  constructor(private http: HttpClient) { }

  public createCustomer(name: string) {
    var req = new CreateCustomerModel(name);
    return this.http.post<Customer>(this.serviceApi, req);
  }

  public getCustomers() {
    return this.http.get<Customer[]>(this.serviceApi);
  }

  public updateCustomer(customer: Customer) {
    return this.http.put<void>(this.serviceApi + customer.customerId, customer);
  }
}
