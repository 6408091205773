import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchResponse, SearchRequest } from './package-search.models';

@Injectable({
  providedIn: 'root'
})
export class PackageSearchService {
  private serviceApi = '/api/search';

  constructor(private http: HttpClient) { }

  public search(searchRequest: SearchRequest) {

    return this.http.post<SearchResponse[]>(this.serviceApi, searchRequest);
  }

}
