export class Truck {
  public truckId: string;
  public name: string;
  public licensePlate: string;


  static clone(truck: Truck): Truck {
    let truck2 = new Truck();
    truck2.licensePlate = truck.licensePlate;
    truck2.name = truck.name;
    truck2.truckId = truck.truckId;
    return truck2;
  }
}
